<template>
  <article class="page-home-editorial-banner">
    <!-- Title -->
    <header
      class="page-home-editorial-banner__header row-1 flex --align-center"
    >
      <div data-animate="banner-text">
        <h2
          v-if="props.content.homeBannerTitle"
          class="title-m"
          v-anim-reveal-text
          v-html="props.content.homeBannerTitle"
        />
        <div
          class="page-home-editorial-banner__header__text text-l m-top-16px"
          v-if="props.content.homeBannerText"
          v-html="props.content.homeBannerText"
        />
        <div class="m-top-24px" v-if="props.content.homeBannerLink">
          <WidgetLinkMainUnderline
            :light="true"
            :link="props.content.homeBannerLink"
          />
        </div>
      </div>
    </header>
    <!-- Video / Image -->
    <div
      class="ratio-full-screen"
      v-anim-parallax-element="{
        target: props.content.homeBannerVideoUrl ? 'video' : 'picture',
      }"
    >
      <WidgetVideo
        v-if="props.content.homeBannerVideoUrl"
        :videoUrl="props.content.homeBannerVideoUrl"
        :autoplay="true"
        :loop="true"
      />
      <WidgetPicture
        v-else-if="props.content.homeBannerImage?.[0]"
        :image="{
          landscape: props.content.landscape?.[0],
          portrait: props.content.portrait?.[0],
        }"
        :lazyLoad="true"
        :alt="props.content.homeBannerImage[0].title"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'PagesHomeEditorialBanner',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  content: Object,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
